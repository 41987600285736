import React, { useEffect, useState } from 'react';
import { Input, Card } from 'antd';
import './App.css';
import data from './data/family_tree.json'; // Adjust the path to your Kong data.
import images from './data/kongz_svg_image_lookup.json'; // Adjust the path to your image data.

interface Kong {
  matron: string;
  sire: string;
}

interface KongData {
  [id: string]: Kong;
}

interface ImageData {
  [id: string]: string;
}

const kongzData: KongData = data;
const kongImages: ImageData = images;

const { Meta } = Card;
const { Search } = Input;

const App: React.FC = () => {
  const [result, setResult] = useState<JSX.Element | null>(null);
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    document.title = 'Kongz Family Tree';
  }, []);

  const handleSearch = (value: string) => {
    const parsedId = parseInt(value);

    const isGen = parsedId >= 1 && parsedId <= 1000;

    if (parsedId >= 1 && parsedId <= 5000) {
      const queriedKong = (
        <div key={value} className="card-container">
          <div className="card-item">
            <Card
              className="clickable queried-kong-card"
              cover={<img alt={`Kong ${value}`} src={kongImages[value]} />}
              onClick={() => handleCardClick(value)}
            >
              <Meta title={`CyberKong ${value}`} />
            </Card>
          </div>
        </div>
      );

      let kongzGrid: JSX.Element[] = [];

      if (parsedId >= 1 && parsedId <= 1000) {
        // Get children
        const children = Object.entries(kongzData)
          .filter(
            ([childId, { matron, sire }]) => matron === value || sire === value
          )
          .map(([childId]) => (
            <div key={childId} className="card-item">
              <Card
                className="clickable baby-parent-card"
                cover={
                  <img
                    alt={`Baby CyberKongz ID ${childId}`}
                    src={kongImages[childId]}
                  />
                }
                onClick={() => handleCardClick(childId)}
              >
                <Meta title={`Baby ${childId}`} />
              </Card>
            </div>
          ));

        kongzGrid = children;
      } else if (parsedId >= 1001 && parsedId <= 5000) {
        // Get parents
        const parents = kongzData[value];
        const matronId = parents?.matron;
        const sireId = parents?.sire;

        if (matronId) {
          kongzGrid.push(
            <div key={matronId} className="card-item">
              <Card
                className="clickable baby-parent-card"
                cover={
                  <img
                    alt={`CyberKongz ${matronId}`}
                    src={kongImages[matronId]}
                  />
                }
                onClick={() => handleCardClick(matronId)}
              >
                <Meta title={`Gen ${matronId}`} />
              </Card>
            </div>
          );
        }

        if (sireId) {
          kongzGrid.push(
            <div key={sireId} className="card-item">
              <Card
                className="clickable baby-parent-card"
                cover={<img alt={`Kong ${sireId}`} src={kongImages[sireId]} />}
                onClick={() => handleCardClick(sireId)}
              >
                <Meta title={`Gen ${sireId}`} />
              </Card>
            </div>
          );
        }
      }

      setResult(
        <div>
          {queriedKong}
          <h2>{isGen ? 'Baby' : 'Parent'} Kongz</h2>
          <div className="card-container">
            {kongzGrid.length > 0 ? kongzGrid : <p>No Babies found.</p>}
          </div>
        </div>
      );
    } else {
      setResult(
        <p style={{ color: 'red' }}>
          Invalid ID. Please enter a valid ID (1-5000).
        </p>
      );
    }
  };

  const handleCardClick = (kongId: string) => {
    setInputValue(kongId); // Set the input field's value to the clicked Kong ID
    handleSearch(kongId); // Call handleSearch with Kong ID
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>CyberKongz Family Tree</h1>
        <div className="search-bar-container">
          <Search
            placeholder="Enter Kongz ID"
            enterButton="Submit"
            size="large"
            value={inputValue} // Set the input field's value
            onChange={(e) => setInputValue(e.target.value)} // Update inputValue when the input changes
            onSearch={handleSearch}
          />
        </div>
        {result}
      </header>
    </div>
  );
};

export default App;
